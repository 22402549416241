<template>
  <div>
    <!-- 搜索 -->
    <div class="search">
      <el-row>
        <el-col :span="4">
          <div class="search_input">
            <el-input
              v-model="search.condition"
              placeholder="请输入查找昵称"
            ></el-input>
          </div>
        </el-col>
        <el-col :span="4">
          <div class="search_input">
            <el-select v-model="search.category">
              <el-option
                v-for="item in optionsType"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </div>
        </el-col>
        <el-col :span="5">
          <div class="search_input">
            <el-button class="btn" type="primary" @click="reset"
              >重置</el-button
            >
            <el-button class="btn" type="primary" @click="getSearch"
              >搜索</el-button
            >
          </div>
        </el-col>
        <el-col :offset="8" :span="1">
          <div class="search_input">
            <el-button
              class="btn"
              icon="el-icon-plus"
              @click="handleClick"
              type="primary"
              >添加</el-button
            >
          </div>
        </el-col>
      </el-row>
    </div>
    <!-- 表格 -->
    <div class="table">
      <el-table :data="tableData" style="width: 100%">
        <el-table-column type="index" align="center" label="序号">
        </el-table-column>
        <el-table-column prop="goodsName" align="center" label="商品名称">
        </el-table-column>
        <el-table-column prop="goodsNum" align="center" label="剩余个数">
        </el-table-column>
        <el-table-column prop="integralGoods" align="center" label="兑换积分">
        </el-table-column>
        <el-table-column
          prop="category"
          :formatter="categoryFormatter"
          align="center"
          label="商品类型"
        >
        </el-table-column>
        <el-table-column prop="createTime" align="center" label="创建时间">
        </el-table-column>
        <el-table-column fixed="right" align="center" label="操作" width="250">
          <template slot-scope="scope">
            <el-button size="mini" type="text" @click="handleDetails(scope.row)"
              >查看</el-button
            >
            <el-button size="mini" type="text" @click="handleClick(scope.row)"
              >编辑</el-button
            >
            <el-button
              size="mini"
              type="text"
              @click="onPutaway(scope.row.id)"
              :disabled="scope.row.sellStatus == 1"
              >上架</el-button
            >
            <el-button
              size="mini"
              type="text"
              @click="onUnputaway(scope.row.id)"
              :disabled="scope.row.sellStatus == 0"
              >下架</el-button
            >
            <el-button size="mini" type="text" @click="onDel(scope.row.id)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination" v-if="page">
        <el-pagination
          @current-change="handleCurrentChange"
          :page-size="page.pageSize"
          layout="total, prev, pager, next"
          :total="page.total"
        >
        </el-pagination>
      </div>
    </div>
    <!-- 详情 -->
    <el-dialog title="详情" :visible.sync="detailsVisible" width="20%">
      <el-form label-width="80px" :model="FormDetails">
        <el-form-item label="商品名称">
          <el-input disabled v-model="FormDetails.goodsName"></el-input>
        </el-form-item>
        <el-form-item label="剩余个数">
          <el-input disabled v-model="FormDetails.goodsNum"></el-input>
        </el-form-item>
        <el-form-item label="兑换积分">
          <el-input disabled v-model="FormDetails.integralGoods"></el-input>
        </el-form-item>
        <el-form-item label="商品类型">
          <el-select disabled v-model="FormDetails.category">
            <el-option
              v-for="item in optionsType"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="审核原因" prop="reason">
          <el-input
            disabled
            v-model="FormDetails.content"
            type="textarea"
            rows="5"
            resize="none"
          ></el-input>
        </el-form-item>
        <el-form-item label="商品图片">
          <el-image style="width: 100px; height: 100px" :src="img"> </el-image>
        </el-form-item>
      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button @click="detailsVisible = false">取 消</el-button>
        <el-button type="primary" @click="detailsVisible = false"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <!-- 添加 -->
    <el-dialog
      :title="title"
      :visible.sync="dialogVisible"
      :before-close="onClose"
      width="30%"
    >
      <el-form label-width="80px" ref="Form" :rules="rulesForm" :model="Form">
        <el-form-item label="商品名称" prop="goodsName">
          <el-input v-model="Form.goodsName"></el-input>
        </el-form-item>
        <el-form-item label="剩余个数" prop="goodsNum">
          <el-input v-model="Form.goodsNum"></el-input>
        </el-form-item>
        <el-form-item label="兑换积分" prop="integralGoods">
          <el-input v-model="Form.integralGoods"></el-input>
        </el-form-item>
        <el-form-item label="商品类型" prop="category">
          <el-select v-model="Form.category">
            <el-option
              v-for="item in optionsType"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="商品描述" prop="content">
          <el-input
            v-model="Form.content"
            type="textarea"
            rows="5"
            resize="none"
          ></el-input>
        </el-form-item>
        <el-form-item label="商品图片">
          <el-upload
            action="#"
            ref="upload"
            :file-list="fileList"
            :limit="1"
            :class="{ disabled: uploadDisabled }"
            list-type="picture-card"
            :on-remove="handleRemove"
            :http-request="uploadSectionFile"
          >
            <i slot="default" class="el-icon-plus"></i>
          </el-upload>
          <!-- <el-image
            @click="handleImage"
            v-if="dialogImageUrl"
            style="width: 100px; height: 100px"
            :src="dialogImageUrl"
          >
          </el-image> -->
        </el-form-item>
      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button @click="onClose">取 消</el-button>
        <el-button type="primary" @click="onSubmit('Form')">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "goods",
  components: {},
  data() {
    return {
      tableData: [],
      page: {
        pageNum: 1,
        pageSize: 10,
        total: 0,
      },
      detailsVisible: false,
      dialogVisible: false,
      FormDetails: {},
      optionsType: [
        { id: 1, name: "杂货铺" },
        { id: 2, name: "优生活" },
        { id: 3, name: "健康屋" },
      ],
      img: "",
      Form: {
        goodsName: "",
        goodsNum: undefined,
        integralGoods: undefined,
        category: undefined,
        content: "",
        urlsBean: [],
      },
      fileList: [],
      //   dialogVisibleImg: false,
      uploadDisabled: false,
      title: "",
      name: "Form",
      search: {
        condition: "",
        category: "",
      },
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    async getList() {
      const res = await this.$get("/goods/getAllGoods", {
        ...this.page,
        ...this.search,
      });
      if (res.flag) {
        this.tableData = res.data.rows;
        this.page.total = res.data.total;
      }
    },
    categoryFormatter(row) {
      if (row.category == 1) {
        return "杂货铺";
      } else if (row.category == 2) {
        return "优生活";
      } else {
        return "健康屋";
      }
    },
    // 详情
    handleDetails(row) {
      this.FormDetails = row;
      this.img = row.urlsBean[0];
      this.detailsVisible = true;
    },
    // 修改
    handleClick(row) {
      if (row.id) {
        this.title = "修改商品";
        this.Form = Object.assign({}, this.Form, row);
        let obj = [{ name: "1", url: row.urlsBean[0] }];
        this.fileList = obj;
        this.dialogVisible = true;
        this.uploadDisabled = true;
      } else {
        this.title = "新增商品";
        this.dialogVisible = true;
      }
    },
    // 图片上传
    async uploadSectionFile(params) {
      const fromData = new FormData();
      fromData.append("files", params.file);
      console.log(fromData);
      const res = await this.$post("/clue/upLoad", fromData);
      console.log(res);
      if (res.flag) {
        this.Form.urlsBean.push(res.data[0]);
        this.uploadDisabled = true;
      }
    },
    handleRemove() {
      this.Form.urlsBean = [];
      this.uploadDisabled = false;
      this.$refs.upload.clearFiles();
    },
    onClose() {
      this.$confirm("确认关闭？")
        .then((_) => {
          this.dialogVisible = false;
          this.Form = {
            goodsName: "",
            goodsNum: undefined,
            integralGoods: undefined,
            category: undefined,
            content: "",
            urlsBean: [],
          };
          this.fileList = [];
          this.uploadDisabled = false;
          done();
        })
        .catch((_) => {});
    },
    onSubmit(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          console.log(!this.Form.urlsBean.length);
          if (!this.Form.urlsBean.length) {
            this.$message.error("请上传图片");
          } else {
            if (this.Form.id) {
              const res = await this.$put("/goods/updateGoods", this.Form);
              console.log(res);
              if (res.flag) {
                this.dialogVisible = false;
                this.Form = {
                  goodsName: "",
                  goodsNum: undefined,
                  integralGoods: undefined,
                  category: undefined,
                  content: "",
                  urlsBean: [],
                };
                this.fileList = [];
                this.uploadDisabled = false;
                this.$message.success(res.message);
              }
            } else {
              const res = await this.$post("/goods/addGoods", this.Form);
              if (res.flag) {
                this.dialogVisible = false;
                this.Form = {
                  goodsName: "",
                  goodsNum: undefined,
                  integralGoods: undefined,
                  category: undefined,
                  content: "",
                  urlsBean: [],
                };
                this.fileList = [];
                this.uploadDisabled = false;
                this.$message.success(res.message);
              } else {
                this.$message.error(res.message);
              }
            }
            this.getList();
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },

    handleCurrentChange(n) {
      this.page.pageNum = n;
      this.getList();
    },
    reset() {
      this.page.pageNum = 1;
      this.page.pageSize = 10;
      this.search.condition = "";
      this.search.category = "";
      this.getList();
    },
    getSearch() {
      this.page.pageNum = 1;
      this.page.pageSize = 10;
      this.getList();
    },
    // 上架
    async onPutaway(id) {
      const res = await this.$put(`/goods/onSell/${id}`);
      if (res.flag) {
        this.$message.success(res.message);
        this.getList();
      } else {
        this.$message.error(res.message);
      }
    },
    // 下架
    async onUnputaway(id) {
      const res = await this.$put(`/goods/offSell/${id}`);
      if (res.flag) {
        this.$message.success(res.message);
        this.getList();
      } else {
        this.$message.error(res.message);
      }
    },
    onDel(id) {
      this.$confirm("确认删除？")
        .then(async (_) => {
          const res = await this.$del(`/goods/deleteGoods/${id}`);
          if (res.flag) {
            this.$message.success(res.message);
            this.getList();
          } else {
            this.$message.error(res.message);
          }
          done();
        })
        .catch((_) => {});
    },
  },
  computed: {
    rulesForm() {
      return {
        goodsName: [
          { required: true, message: "请输入商品名称", trigger: "blur" },
        ],
        goodsNum: [
          { required: true, message: "请输入剩余个数", trigger: "blur" },
        ],
        integralGoods: [
          { required: true, message: "请输入兑换积分", trigger: "blur" },
        ],
        category: [
          { required: true, message: "请输入商品类型", trigger: "change" },
        ],
        content: [
          { required: true, message: "请输入商品描述", trigger: "blur" },
        ],
      };
    },
  },
};
</script>

<style lang="less" scoped>
.search {
  width: 100%;
  height: 80px;
  background: #ffffff;
  border-radius: 16px;
  margin-top: 16px;
  .search_input {
    height: 80px;
    display: flex;
    align-items: center;
    padding: 0 10px 0 30px;
    .btn {
      width: 110px;
      height: 40px;
    }
  }
}
.table {
  border-bottom-right-radius: 16px;
  border-bottom-left-radius: 16px;
  background-color: #fff;
  overflow-y: auto;
  border-radius: 16px;
  .pagination {
    padding: 30px 0;
    display: flex;
    justify-content: flex-end;
    /deep/ .el-pagination {
      .el-pagination__sizes {
        margin-right: 100px;
      }
      .el-pagination__jump {
        margin-right: 100px;
      }
    }
  }
}
.el-form-item {
  text-align: left;
}
.disabled {
  /deep/ .el-upload--picture-card {
    display: none;
  }
}
</style>